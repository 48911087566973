export const ACCESSIBLE_ROUTES = {
  superuser: [
    "",
    "start",
    "history",
    "new-upload",
    "previous-uploads",
    "data-management",
    "bi-marketplace",
    "script-marketplace",
    "users",
    "user_guide",
  ],
  user: [
    "",
    "start",
    "history",
    "new-upload",
    "previous-uploads",
    "data-management",
    "bi-marketplace",
    "user_guide",
    "script-marketplace",
  ],
  bi_admin: [
    "",
    "start",
    "history",
    "new-upload",
    "previous-uploads",
    "data-management",
    "bi-marketplace",
    "user_guide",
    "script-marketplace",
  ],
  script_admin: [
    "",
    "start",
    "history",
    "new-upload",
    "previous-uploads",
    "data-management",
    "bi-marketplace",
    "user_guide",
    "script-marketplace",
  ],
};
