import Vue from "vue";
import Router from "vue-router";
import { ActiveUser } from "@/services/user";
import { IS_MZ_BRANDING } from "@/constants/config";
import { ACCESSIBLE_ROUTES as accessRoutes } from "@/constants/routes";
import { users } from "@/services/users";
import store from "../store";

// Containers
const TheContainer = () => import("@/containers/TheContainer");
const AccountContainer = () => import("@/containers/AccountContainer");

// Views
const Start = () => import("@/views/start/Start");
const History = () => import("@/views/history/History");
const Home = () => import("@/views/home/Home");
const Userguide = () => import("@/views/userguide/Userguide");
const Templates = () => import("@/views/templates/Templates");
const Anonymize = () => import("@/views/anonymize/Anonymize");
const BiMarketplace = () => import("@/views/bi-marketplace");
const SingleReport = () => import("@/views/single-report");
const ScriptMarketplace = () => import("@/views/script-marketplace");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Login = () => import("@/views/pages/Login");
const ForgotPassword = () => import("@/views/pages/ForgotPassword");
const ResetPassword = () => import("@/views/pages/ResetPassword");

// Users
const Users = () => import("@/views/users/UsersView");
const User = () => import("@/views/users/UserView");

Vue.use(Router);

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

const isOpenAccess = (route) =>
  route.matched.some((route) => route.meta?.isOpenAccess);

const isFound = (route) => route.matched[0]?.name !== "NotFound";

router.beforeEach(async (to, from, next) => {
  await users.getActiveUserInfo().then((res) => {
    store.commit("setIsLoggedIn", res.success);
    store.commit("setRole", res.roles);
    ActiveUser.setUserInfo(res);
    if (!res.success) {
      ActiveUser.clear();
    }
  });
  if (to.meta?.getTitle) to.meta.title = to.meta.getTitle(to);
  const isAuthenticated = ActiveUser.get();
  if (!isAuthenticated && !isOpenAccess(to)) {
    if (isFound(to)) {
      localStorage.setItem("pathToLoadAfterLogin", to.path);
    }
    return next({ name: IS_MZ_BRANDING ? "Login" : "DataMorph Login" });
  }

  if (!isAuthenticated) {
    return next();
  }
  const roles = ActiveUser.getRoles();
  let userRole = roles.find((i) => i === "superuser") || roles[0];
  if (!userRole) {
    try {
      const user_me = await UserManagementService.user_me({
        email: JSON.parse(localStorage.data_processor_user).email,
      });
      userRole = user_me.data.data.role;
    } catch (e) {
      localStorage.removeItem("data_processor_user");
      localStorage.removeItem("access_token");
      ActiveUser.clear();
    }
  }
  const accessRoute = accessRoutes[userRole] || [];
  let isRouteAllowed = false;
  if (
    accessRoute.includes(to.fullPath.substring(1).split("?")[0].split("/")[0])
  ) {
    isRouteAllowed = true;
  }
  if (isRouteAllowed) {
    return next();
  }
  next({ name: "DataMorph" });
});

export default router;

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/",
      name: "DataMorph",
      component: TheContainer,
      children: [
        {
          path: "",
          name: "DataMorph",
          component: Home,
        },
        {
          path: "new-upload",
          name: "New upload",
          component: Start,
        },
        {
          path: "previous-uploads",
          name: "Previous uploads",
          component: History,
        },
        {
          path: "data-management",
          name: "Data management",
          component: Templates,
        },
        {
          path: "anonymize",
          name: "Randomize",
          component: Anonymize,
        },
        {
          path: "bi-marketplace",
          name: "BI Marketplace",
          component: BiMarketplace,
        },
        {
          path: "bi-marketplace/report/:id",
          name: "Report",
          component: SingleReport,
        },
        {
          path: "script-marketplace",
          name: "Script Marketplace",
          component: ScriptMarketplace,
        },
        {
          path: "users",
          meta: {
            label: "Users",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Users",
              component: Users,
            },
            {
              path: ":id",
              meta: {
                label: "User Details",
              },
              name: "User",
              component: User,
            },
          ],
        },
        {
          path: "user_guide",
          name: "Users guide",
          component: Userguide,
        },
      ],
    },
    {
      path: "/oauth",
      meta: {
        isOpenAccess: true,
      },
      component: AccountContainer,
      children: [
        {
          path: "",
          name: "OAuth Login",
          component: Login,
        },
      ],
    },
    {
      path: "/account",
      name: "account",
      meta: {
        isOpenAccess: true,
      },
      component: AccountContainer,
      children: [
        {
          path: "login",
          name: "Login",
          component: Login,
        },
        {
          path: "forgot",
          name: "Forgot password",
          component: ForgotPassword,
        },
        {
          path: "reset/:token",
          name: "Password reset",
          component: ResetPassword,
        },
        {
          path: "login_management",
          name: "DataMorph Login",
          component: Login,
          props: {
            show_email_login: true,
          },
        },
      ],
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
      ],
    },
    {
      path: "*",
      name: "NotFound",
      component: TheContainer,
      children: [{ path: "*", component: Page404 }],
    },
  ];
}
