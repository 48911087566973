import { BASE_URL } from "@/constants/config";
import { request } from "@/utils/request";

export const USER_ENDPOINTS = {
  list: (query) => `${BASE_URL}api/users/?${query}`,
  all_list: `${BASE_URL}api/users/full_list/`,
  register: `${BASE_URL}auth/register`,
  me: `${BASE_URL}api/users/me`,
  detail: (id) => `${BASE_URL}users/${id}`,
  roles: `${BASE_URL}api/roles/`,
  edit_clients: `${BASE_URL}api/users/edit_clients`,
  fetch_field: `${BASE_URL}api/users/fetch_field`,
  msi_login: `${BASE_URL}api/users/msi_login`,
};

class Users {
  async getActiveUserInfo() {
    const { data } = await request({
      url: USER_ENDPOINTS.me,
      method: "POST",
    });
    return data;
  }

  async getUser(id) {
    const { data } = await request({
      url: USER_ENDPOINTS.detail(id),
    });
    return data;
  }

  async getUsers() {
    const { data } = await request({
      url: USER_ENDPOINTS.all_list,
    });
    return data;
  }

  async createUser(userData) {
    const { data } = await request({
      url: USER_ENDPOINTS.register,
      method: "POST",
      body: userData,
    });
    return data;
  }

  async fetchField(userData) {
    const { data } = await request({
      url: USER_ENDPOINTS.fetch_field,
      method: "POST",
      body: { field: "email" },
    });
    return data;
  }

  async editUser(id, userData) {
    const { data } = await request({
      url: USER_ENDPOINTS.detail(id),
      method: "PATCH",
      body: userData,
    });
    return data;
  }

  async deleteUser(id) {
    return await request({
      url: USER_ENDPOINTS.detail(id),
      method: "DELETE",
    });
  }

  async getRoles() {
    const { data } = await request({
      url: USER_ENDPOINTS.roles,
    });
    return data;
  }

  async editClients(uuid_id, new_clients) {
    const { data } = await request({
      url: USER_ENDPOINTS.edit_clients,
      method: "POST",
      body: {
        uuid_id,
        new_clients,
      },
    });
    return data;
  }

  async getMsiLogin() {
    const { data } = await request({
      url: USER_ENDPOINTS.msi_login,
      method: "GET",
    });
    return data;
  }
  async getLoginToken(url) {
    const { data, res } = await request({
      url,
      method: "POST",
      skipRedirect: true,
    });
    return {
      data,
      res,
    };
  }
}

export const users = new Users();
