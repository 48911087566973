<template>
  <section>
    <router-view />
    <HelpMenu  v-if="isLoggedIn" />
  </section>
</template>

<script>
import HelpMenu from "@/components/HelpMenu.vue";

export default {
  name: "App",
  components: { HelpMenu },
  async mounted() {
    const code = this.getSearchParam();

    if (!code.includes("null")) {
      localStorage.setItem("code", code);
    }
  },
  methods: {
    getSearchParam() {
      const params = new window.URLSearchParams(window.location.search);
      const fullCode = `code=${params.get("code")}&client_info=${params.get(
        "client_info"
      )}&state=${params.get("state")}&session_state=${params.get(
        "session_state"
      )}`;
      return fullCode || null;
    },
  },
  watch: {
    $route(to) {
      document.title =
        to.meta.title ||
        to.params[to.meta.paramKey] ||
        to.meta.label ||
        to.name ||
        "Forvis Mazars";
    },
  },
    computed:{
        isLoggedIn() {
            return this.$store.state.isLoggedIn;
        },
    }
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
