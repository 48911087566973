<template>
  <CButton v-bind="$attrs" v-on="$listeners">
    <slot>{{ text }}</slot>
  </CButton>
</template>

<script>
export default {
  name: 'ButtonUI',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
*::v-deep {
  padding: 12px 30px;

  background: #3f4b62;
  border-radius: 10px;

  @media (max-width: 1439px) {
    padding: 10px 15px;
  }
}
</style>
