<template>
  <div>
    <!--        <CCard class="CardStyle">-->
    <div class="page">
      <MzSelect :value.sync="page" :options="pages" />
    </div>

    <div class="wrapper">
      <img
        :src="getImgUrl(page.value)"
        v-for="page in pages"
        class="image"
        :id="page.value"
      />
    </div>
    <button class="bi-button" @click="onDownload">
      Download "DataMorph - PBIX example.pbix"
    </button>

    <!--      </CCard>-->
  </div>
</template>

<script>
import ButtonUI from "../../ui/buttons/ButtonUI.vue";
import { FilesService } from "@/services/files";

export default {
  components: { ButtonUI },
  name: "Userguide",
  data() {
    return {
      pages: [],
      page: "Table of content",
    };
  },
  async mounted() {
    this.pages = [
      {
        label: "Overview",
        value: "overview",
      },
      {
        label: "New Upload",
        value: "newUpload-1",
      },
      {
        label: "New Upload (2)",
        value: "newUpload-2",
      },
      {
        label: "New Upload (3)",
        value: "newUpload-3",
      },
      {
        label: "New Upload (4)",
        value: "newUpload-4",
      },
      {
        label: "Data Management",
        value: "dataManagement-1",
      },
      {
        label: "Data Management (2)",
        value: "dataManagement-2",
      },
      {
        label: "Data Management (3)",
        value: "dataManagement-3",
      },
      {
        label: "Data Management (4)",
        value: "dataManagement-4",
      },
      {
        label: "BI Marketplace",
        value: "bi-marketplace-1",
      },
      {
        label: "BI Marketplace (2)",
        value: "bi-marketplace-2",
      },
    ];
  },
  watch: {
    page: {
      async handler(e) {
        const element = document.getElementById(e);
        element.scrollIntoView({ behavior: "smooth" });
      },
      deep: true,
    },
  },
  methods: {
    getImgUrl(pic) {
      return require("@/assets/images/" + pic + ".png");
    },
    onDownload() {
      FilesService.downloadFile("DataMorph - PBIX example.pbix")
        .then((res) => res.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = "DataMorph - PBIX example.pbix";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
  },
  // computed: {
  // },
};
</script>

<style scoped lang="scss">
// .wrapper {
//   //padding: 20px;
//   width: 100%;
//   height: 80vh;
//   //overflow-y: scroll;
// }
.page {
  //margin: 20px 20px 0 20px;
}
.image {
  width: 100%;
  margin-bottom: 20px;
}
</style>
