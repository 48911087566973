import { AuthService } from "./auth";
import { BASE_URL } from "@/constants/config";
import { users } from "@/services/users";
import { request } from "@/utils/request";

const userKey = "data_processor_user";
const accessTokenKey = "access_token";

export const USER_ENDPOINTS = {
  user_me: `${BASE_URL}api/users/me`,
  check_gate: `${BASE_URL}api/gate/login_gate`,
};

class User {
  user = null;
  tokenBearer = "Bearer";

  get() {
    if (this.user) {
      return this.user;
    }
    const savedUser = localStorage.getItem(userKey);
    if (savedUser) {
      this.user = JSON.parse(savedUser);
      return this.user;
    }
  }

  clear() {
    const token = this.getToken();
    localStorage.removeItem(userKey);
    this.user = null;
    localStorage.removeItem(accessTokenKey);
    if (token) {
      AuthService.logout(token);
    }
  }

  async set(value) {
    localStorage.setItem(userKey, JSON.stringify(value));
    if (value.accessToken) {
      // lazy-table requires access_token
      localStorage.setItem(accessTokenKey, value.accessToken);
    }
    const userInfo = await users.getActiveUserInfo();
    const userData = { ...userInfo, ...value };
    localStorage.setItem(userKey, JSON.stringify(userData));
    this.user = userData;
  }

  async setUserInfo(value) {
    const storageData = localStorage.getItem(userKey) ? JSON.parse(localStorage.getItem(userKey)) : {}
    const userData = { ...storageData, ...value };
    localStorage.setItem(userKey, JSON.stringify(userData));
    this.user = userData;
  }

  async check_gate(code) {
    const { data, res } = await request({
      url: `${USER_ENDPOINTS.check_gate}?code=${code}`,
      method: "GET",
      skipRedirect: true,
    });

    return {
      data,
      res,
    };
  }

  getToken() {
    return this.get()?.accessToken;
  }

  getRoles() {
    return this.get()?.roles || [];
  }

  getAuthorization() {
    return `${this.tokenBearer} ${this.getToken()}`;
  }

  setToken(token, email) {
    return this.set({ accessToken: token, email: email });
  }

  getLanguage() {
    return this.get()?.language;
  }

  setLanguage(language) {
    if (language !== this.getLanguage()) {
      return this.set({ ...this.get(), language });
    }
  }

  isAdministrator() {
    return this.get()?.roles.includes("superuser");
  }

  isUser() {
    return this.get()?.roles.includes("user");
  }

  isBiAdmin() {
    return this.get()?.roles.includes("bi_admin");
  }
}

export const ActiveUser = new User();
