import "core-js/stable";
import "regenerator-runtime/runtime";
import "vuejs-dialog/dist/vuejs-dialog.min.css";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
// require("moment/locale/fr");

import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@mz/coreui-vue";
import VueI18n from "vue-i18n";
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store";
import Toasted from "vue-toasted";
import VuejsDialog from "vuejs-dialog";
import MzRd from "mz-rd";
import vSelect from "vue-select";
import { BootstrapVue } from "bootstrap-vue";
import OpenIndicator from "./components/VSelectIcon/OpenIndicator.vue";
import VueSkeletonLoader from "skeleton-loader-vue";

vSelect.props.components.default = () => ({
  OpenIndicator,
});

Vue.component("v-select", vSelect);
Vue.component("vue-skeleton-loader", VueSkeletonLoader);
Vue.component('Treeselect', Treeselect);
import { BASE_URL } from "@/constants/config";
import { HelpersMixin } from "@/mixins/helpers";
import { locales } from "@/locales";

Vue.use(CoreuiVue);
Vue.use(BootstrapVue);

Vue.use(MzRd, {
  apiUrl: BASE_URL,
  tokenBearer: "Bearer",
  tableClassess: "mz-table",
});
Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: navigator.language.split("-")[0],
  fallbackLocale: "en",
  messages: locales,
  silentTranslationWarn: true,
});
Vue.use(VuejsDialog);
Vue.use(Toasted, {
  iconPack: "fontawesome",
  theme: "toasted-primary",
  position: "top-center",
  action: {
    text: "Close",
    icon: "close",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
});

Vue.mixin(HelpersMixin);

window.alert = (message, additional = {}) => {
  Vue.toasted.show(message, {
    duration: 5000,
    ...additional,
  });
};

window.confirm = async (message) => {
  try {
    await Vue.dialog.confirm(message, {
      okText: i18n.t("Confirm"),
      cancelText: i18n.t("Cancel"),
    });
    return true;
  } catch {
    return false;
  }
};

Vue.config.performance = true;

Vue.prototype.$log = console.log.bind(console);

new Vue({
  el: "#app",
  i18n,
  router,
  store,
  icons,
  template: "<App/>",
  components: {
    App,
  },
});
