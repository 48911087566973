import en from "./en.json";
import fr from "./fr.json";

const availableLocales = {
  en,
  fr,
};

export const locales = Object.fromEntries(
  Object.entries(availableLocales).map(([locale, translations]) => [
    locale,
    Object.fromEntries(
      Object.entries(translations).filter(([_key, value]) => value)
    ),
  ])
);
