<template>
  <div class="support-modal">
    <CModal
      class="support-modal-body"
      id="Ticket"
      alignment="center"
      :show.sync="visible"
      :closeOnBackdrop="false"
    >
      <template slot="header">
        <h4>Ticket</h4>

        <svg
          @click="$emit('closePopup')"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          class="bi bi-x"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </template>
      <section>
        <div class="ModalWithSelects">
          <!--          <div>-->
          <!--            <label>{{ $t("Title") }}</label>-->
          <!--            <CInput class="input" v-model="message.title" />-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <label>Type</label>-->
          <!--            <SelectUI-->
          <!--              class="type-select"-->
          <!--              v-model="message.type"-->
          <!--              :options="typeFields"-->
          <!--              :searchable="false"-->
          <!--              :clearable="false"-->
          <!--            />-->
          <!--          </div>-->
          <!--          <div>-->
          <!--            <label>Description</label>-->
          <!--            <CTextarea-->
          <!--              class="description-textarea"-->
          <!--              v-model="message.description"-->
          <!--            />-->
          <!--          </div>-->
          <b-form-group label="Title" label-for="title" description="">
            <b-form-input
              id="title"
              v-model="message.title"
              type="text"
              placeholder="Enter title"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Type"
            id="input-group-4"
            label-for="input-4"
            style="position: relative"
          >
            <v-select
              placeholder="Select table"
              :options="typeFields"
              v-model="message.type"
              class="add-tag"
              label="table_name"
            />
          </b-form-group>

          <b-form-group label="Description" label-for="input-2">
            <b-form-textarea
              id="input-2"
              v-model="message.description"
              placeholder="Enter description..."
              rows="4"
              max-rows="4"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </section>
      <template slot="footer">
        <div style="padding: 8px 45px" class="d-flex gap-2">
          <button
            class="btn mt-3 cus-button cuz-button--white"
            @click="closeModal"
            button-type="cancel"
          >
            Cancel
          </button>
          <b-button
            class="btn cus-button mt-3"
            button-type="cancel"
            @click="sendMessage"
            :disabled="isSendBtnDisabldes"
          >
            <div class="btn-loader__wrapper" :class="{ 'one-col': !isLoading }">
              <span>Create</span>

              <CSpinner
                v-if="isLoading"
                color="white"
                style="width: 1.5rem; height: 1.5rem"
              />
            </div>
          </b-button>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import { SupportService } from "@/services/support";

import { mapGetters } from "vuex";
import { ACCESSIBLE_ROUTES as accessRoutes } from "@/constants/routes";
import ButtonUI from "@/ui/buttons/ButtonUI.vue";
import SelectUI from "@/ui/selects/SelectUI.vue";
import "vue-select/dist/vue-select.css";

export default {
  name: "SupportModal",
  props: ["isVisible", "closePopup", "currentPage", "form"],
  components: {
    SelectUI,
    ButtonUI,
  },
  data() {
    return {
      visible: false,
      isSendBtnDisabldes: true,
      isLoading: false,
      typeFields: ["Bug", "Error", "Enhancements"],
      sendMessageText: "Create",
      pageDefaultFields: [
        {
          label: "Optional",
          id: "Optional",
        },
        {
          label: "Book team",
          id: "Book team",
        },
      ],
      pageFields: [],
      message: {
        title: "",
        type: "Bug",
        page: "Optional",
        description: "",
      },
    };
  },
  watch: {
    isVisible(val) {
      this.visible = val;
      if (val) this.updatePageFields();
    },
    visible(val) {
      if (!val) {
        this.closeModal();
      }
    },
    currentPage(page) {
      this.message.page = this.capitalizeFirstLetter(page);
      this.updatePageFields();
    },
    message: {
      async handler(val) {
        let isEmpty = false;

        for (const key in val) {
          if (val[key] === "") {
            isEmpty = true;
            break;
          }
        }

        this.isSendBtnDisabldes = isEmpty;
      },
      deep: true,
    },
  },
  methods: {
    async sendMessage() {
      this.isSendBtnDisabldes = true;
      this.sendMessageText = "Send";
      this.isLoading = true;
      let message;
      if (this.form) {
        const { data } = await SupportService.sendImage(this.form);

        message = {
          title: this.message.title,
          type: this.message.type,
          message: `Page: ${this.message.page}, Description: ${this.message.description}`,
          attachments: [
            {
              url: data.url,
              markdown: data.markdown,
            },
          ],
        };
      } else {
        message = {
          title: this.message.title,
          type: this.message.type,
          message: `Page: ${this.message.page || "Optional"}, Description: ${
            this.message.description
          }`,
        };
      }
      // message = {
      //   title: this.message.title,
      //   type: this.message.type,
      //   message: `Page: ${this.message.page || "Optional"}, Description: ${
      //     this.message.description
      //   }`,
      // };
      await SupportService.sendMessage(message);
      this.isSendBtnDisabldes = true;
      this.isLoading = false;
      this.closeModal();
      alert("Thank you for your feedback!");
    },
    closeModal() {
      this.message = {
        title: "",
        type: "Bug",
        page: "Optional",
        description: "",
      };
      this.$emit("closePopup");
    },
    updatePageFields() {
      const firstRole = this.$store.state.role?.[0] || "";
      const accessRoleRoutes = accessRoutes[firstRole.toLowerCase()];

      const pagesToEdit = {
        "Workload page": "Planning workload",
        Workload: "Planning workload",
        "book-team": "Book team",
        "Internal Bookings": "Internal bookings",
        "Clients & Projects": "Clients & projects",
        Users: "User's management",
      };

      const pages = [];

      for (const item of accessRoleRoutes) {
        const currentPage = this.capitalizeFirstLetter(
          item.split("/")[item.split("/").length - 1]
        ).replaceAll("_", " ");

        if (currentPage in pagesToEdit) {
          pages.push({
            id: pagesToEdit[currentPage],
            label: pagesToEdit[currentPage],
          });
        } else {
          pages.push({ id: currentPage, label: currentPage });
        }
      }

      this.pageFields = [...this.pageDefaultFields, ...pages];

      if (pagesToEdit[this.currentPage]) {
        this.message.page = pagesToEdit[this.currentPage];
      } else {
        this.message.page = this.currentPage.trim();
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  computed: {
    // ...mapGetters({
    //   role: "user/getRole",
    //   user: "user/getUser",
    // }),
  },
};
</script>
<style scoped lang="scss">
.ModalWithSelects {
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-bottom: 20px;
}
.support-modal::v-deep {
  .modal-dialog.modal-sm {
    min-width: 50% !important;
    padding-left: 25%;
  }
}

.modal-body {
  min-height: 50vh;
}
.support-modal-body::v-deep .modal-dialog {
  min-width: 50vw;
}

.support-modal-body::v-deep .modal-footer {
  padding: 0;
  margin-bottom: 5px;
}

.input::v-deep input,
.description-textarea::v-deep textarea {
  border: 1px solid #eaecf0;
  background-color: #f5f6f8;
  border-radius: 10px;

  @media (max-width: 1439px) {
    border-radius: 7px;
  }
}

.input::v-deep {
  input {
    padding-left: 10px;
    height: 48px;

    @media (max-width: 1439px) {
      height: 35px;
    }
  }
}

.description-textarea::v-deep {
  textarea {
    height: 100px !important;
    max-height: unset !important;
    resize: none;

    &:focus {
      color: #2d2d2d;
    }
  }
}

.type-select::v-deep {
  .vue-treeselect__control {
    padding-left: 10px;
  }
}

section {
  padding: 0 20px;
}

.footer {
  margin-bottom: 12px;

  .send-btn {
    margin-left: auto;
  }
}
</style>
