<template>
	<Treeselect
		:value="computedValue"
		:style="getStyles()"
		:valueFormat="valueFormat"
		:options="computedOptions"
		:clearable="clearable"
		@input="handleInput"
		@close="handleClose"
		@deselect="handleDeselect"
		:multiple="multiple"
		:limit="limit"
		:placeholder="placeholder"
		v-bind="$attrs"
		v-on="$listeners"
	/>
	<!-- <label
			slot="option-label"
			slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
			:class="labelClassName"
		>
			{{ $t(node.label) }}
		</label> -->
	<!-- </Treeselect> -->
</template>

<script>
const colors = {
	primary: '#F5F6F8',
	white: '#FFFFFF',
};

export default {
	name: 'SelectUI',
	components: {},
	props: {
		value: {
			type: [String, Number, Array, Object],
		},
		valueFormat: {
			type: String,
			default: 'id',
		},
		color: {
			type: String,
			default: 'primary',
		},
		clearable: {
			Type: Boolean,
			default: true,
		},
		options: {
			type: [Array, Function],
		},
		input: {
			type: Function,
		},
		close: {
			type: Function,
		},
		deselect: {
			type: Function,
		},
		multiple: {
			type: Boolean,
		},
		limit: {
			type: Number,
			default: 1,
		},
		placeholder: {
			type: String,
		},
	},
	computed: {
		computedValue() {
			return this.value;
		},
		computedOptions() {
			return typeof this.options === 'function' ? this.options() : this.options;
		},
	},
	methods: {
		getStyles() {
			return {
				'--select-color': colors[this.color],
			};
		},
		handleInput(event) {
			if (this.input) {
				this.input(event);
			}
		},
		handleClose() {
			if (this.close) {
				this.close();
			}
		},
		handleDeselect(event) {
			if (this.deselect) {
				this.deselect(event);
			}
		},
	},
};
</script>

<style scoped lang="scss">
*::v-deep {
	// color: #737484;
	color: #2d2d2d;

	& div.vue-treeselect__control {
		height: 48px;

		background: var(--select-color);
		// border: 1px solid #eaecf0;
		border-radius: 10px;

		@media (max-width: 1439px) {
			height: 35px;
			border-radius: 7px;
		}
	}

	& div.vue-treeselect__control {
		background: var(--select-color);
	}

	& .vue-treeselect__placeholder {
		color: #737484;
	}

	& .vue-treeselect__single-value {
		color: #2d2d2d;
	}

	& .vue-treeselect__menu {
		background-color: var(--select-color);
	}
}
</style>
