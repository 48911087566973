import { request } from "@/utils/request";
import { BASE_URL } from "@/constants/config";
import { ActiveUser } from "@/services/user";

export const FILES_ENDPOINTS = {
  upload: `${BASE_URL}api/files/upload/`,
  uploadV2: `${BASE_URL}api/files/v2/upload/`,
  download: `${BASE_URL}api/files/download/`,
  downloadV2: (id) => `${BASE_URL}api/files/v2/download/${id}`,
  delete: `${BASE_URL}api/files/delete/`,
  list: `${BASE_URL}api/files/list/`,
  availableFileTypes: `${BASE_URL}api/files/available_file_types/`,
};

class Files {
  async removeFile(query) {
    const { data } = await request({
      url: FILES_ENDPOINTS.delete,
      method: "DELETE",
      query,
    });
    return data;
  }

  async fetchAvailableFileTypes() {
    const { data } = await request({
      url: FILES_ENDPOINTS.availableFileTypes,
    });
    return data;
  }

  async uploadFile(payload) {
    const { data } = await request({
      url: FILES_ENDPOINTS.uploadV2,
      method: "POST",
      formData: payload,
    });
    return data;
  }

  async fetchFiles() {
    const { data } = await request({
      url: FILES_ENDPOINTS.list,
    });
    return data;
  }

  async downloadFile(id) {
    const data = await fetch(FILES_ENDPOINTS.downloadV2(id), {
      headers: { Authorization: ActiveUser.getAuthorization() },
    });
    return data;
  }
}

export const FilesService = new Files();
